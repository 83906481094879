import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            "en-US": { translation: require("./translations/en-US.json") },
            "fr-FR": { translation: require("./translations/fr-FR.json") },
            "ja-JP": { translation: require("./translations/ja-JP.json") },
            "de-DE": { translation: require("./translations/de-DE.json") },
            "it-IT": { translation: require("./translations/it-IT.json") },
            "es-ES": { translation: require("./translations/es-ES.json") },
            "nl-NL": { translation: require("./translations/nl-NL.json") },
            "pt-PT": { translation: require("./translations/pt-PT.json") },
        },
        debug: false,
        fallbackLng: {
            fr: ["fr-FR"],
            jp: ["ja-JP"],
            de: ["de-DE"],
            it: ["it-IT"],
            es: ["es-ES"],
            nl: ["nl-NL"],
            pt: ["pt-PT"],
            default: ["en-US"],
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
