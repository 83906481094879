import { ROLES } from "constants/app";
import { hasArchitecturalLicense } from "utils/helperFunctions";
import { getOrganizationPaymentProfileMiddleware, getUserProfile } from "./user";
import { ACTION_TYPES } from "store/actionTypes";

export const updateLoggedInUser = async (dispatch) => {
    const userProfileResponse = await getUserProfile();

    if (!userProfileResponse?.data) {
        return null;
    }

    const userProfileData = userProfileResponse.data;
    const isNfrAdmin = userProfileResponse.data.isNfrAdmin;
    const isBetaUser = userProfileResponse.data.isBetaUser;

    const hasOrganization = !!userProfileData?.orgDetails?.organizationId?.length;
    const organization = hasOrganization
        ? {
              id: userProfileData?.orgDetails?.organizationId,
              name: userProfileData?.orgDetails?.organizationName,
              isTrialUsed: userProfileData?.orgDetails?.isTrialUsed,
              isPromotionalTrialUsed: userProfileData?.orgDetails?.isPromotionalTrialUsed,
          }
        : null;
    const loggedInUser = {
        id: userProfileData.id,
        email: userProfileData.email,
        access: userProfileData.access,
        isFreemiumUser: !hasArchitecturalLicense(userProfileData),
        firstName: userProfileData.firstName,
        lastName: userProfileData.lastName,
        acceptedTOS: !!userProfileData?.tosAccepted,
        isNfrAdmin,
        isBetaUser,
        company: userProfileData?.company ?? "",
        country: userProfileData?.country ?? "",
        countryCode: userProfileData?.countryCode ?? "",
        role: userProfileData?.professionalTitle ?? "",
        isAdmin: userProfileData.isAdmin && !userProfileData.isNfrAdmin,
        organization: organization,
        subscription: null,
        paddlePaymentInfo: null,
        paddleBillingHistory: null,
        roleId: ROLES[0].id,
        userGroupIds: [],
        organizationUsers: isNfrAdmin ? userProfileData?.organizationUsers ?? [] : [],
    };

    if (hasOrganization) {
        const paymentProfile = await getOrganizationPaymentProfileMiddleware(organization.id);
        const paymentData = paymentProfile?.data; // will always have data -- null check is syntactic sugar here
        loggedInUser.subscription = paymentData?.subscription ?? null;
        // very unlikely to be null but checking
        loggedInUser.paddlePaymentInfo = paymentData?.paddlePaymentInfo ?? null;
        loggedInUser.paddleBillingHistory = paymentData?.paddleBillingHistory ?? null;
    }

    dispatch({ type: ACTION_TYPES.SET_LOGGED_IN_USER, payload: loggedInUser });
    return loggedInUser;
};
