const {
    REACT_APP_API_SERVER_URL,
    REACT_APP_X_API_KEY,
    REACT_APP_AUTH_BASE_URL,
    REACT_APP_IFC_NAV_URL,
    REACT_APP_EDITOR_AUTH_CLIENT_ID,
    REACT_APP_EDITOR_AUTH_CLIENT_SECRET,
    REACT_APP_PLUGIN_AUTH_CLIENT_ID,
    REACT_APP_PLUGIN_AUTH_CLIENT_SECRET,
    REACT_APP_ALLOWED_MESSAGE_ORIGIN,
    REACT_APP_MIDDLEWARE_API_SERVER_URL,
    REACT_APP_MIDDLEWARE_X_API_KEY,
    REACT_APP_WS_SERVER_URL,
    REACT_APP_PADDLE_PRODUCT_NAME_WITH_TRIAL,
    REACT_APP_PADDLE_PRODUCT_NAME_WITHOUT_TRIAL,
    REACT_APP_PADDLE_PRODUCT_ID,
    REACT_APP_BIMPLUS_ALLOWED_ORIGIN,
    REACT_APP_SEGMENT_WRITE_KEY,
} = process.env;

export const baseURL = REACT_APP_API_SERVER_URL
    ? REACT_APP_API_SERVER_URL
    : "https://inside-dev-api.solibri.com";

export const ifcNacURL = REACT_APP_IFC_NAV_URL ? REACT_APP_IFC_NAV_URL : baseURL;

export const wsServerURL = REACT_APP_WS_SERVER_URL ? REACT_APP_WS_SERVER_URL : baseURL;

export const segmentWriteKey = REACT_APP_SEGMENT_WRITE_KEY ? REACT_APP_SEGMENT_WRITE_KEY : null;

export const apiKey = REACT_APP_X_API_KEY
    ? REACT_APP_X_API_KEY
    : "ubQZwJdRL;YLHxboPhvB8iEAocAUbvpce4b";

export const authClientIdEditor = REACT_APP_EDITOR_AUTH_CLIENT_ID
    ? REACT_APP_EDITOR_AUTH_CLIENT_ID
    : "solibri-cloud Editor localhost";

export const authClientSecretEditor = REACT_APP_EDITOR_AUTH_CLIENT_SECRET
    ? REACT_APP_EDITOR_AUTH_CLIENT_SECRET
    : "solibri-cloud Editor localhost";

export const authClientIdPlugin = REACT_APP_PLUGIN_AUTH_CLIENT_ID
    ? REACT_APP_PLUGIN_AUTH_CLIENT_ID
    : "solibri-cloud Plugin localhost";

export const authClientSecretPlugin = REACT_APP_PLUGIN_AUTH_CLIENT_SECRET
    ? REACT_APP_PLUGIN_AUTH_CLIENT_SECRET
    : "solibri-cloud Plugin localhost";

export const authBaseUrl = REACT_APP_AUTH_BASE_URL
    ? REACT_APP_AUTH_BASE_URL
    : "https://login-dev.solibri.com";

export const tendukeBaseURL = REACT_APP_AUTH_BASE_URL
    ? REACT_APP_AUTH_BASE_URL
    : "https://login-dev.solibri.com";

export const middlewareBaseURL = REACT_APP_MIDDLEWARE_API_SERVER_URL
    ? REACT_APP_MIDDLEWARE_API_SERVER_URL
    : "https://w1z4pfsi41.execute-api.eu-west-1.amazonaws.com/";

export const middlewareApiKey = REACT_APP_MIDDLEWARE_X_API_KEY
    ? REACT_APP_MIDDLEWARE_X_API_KEY
    : "65p9va5E957yqr8jXWWNJ4nSW8zwb7Ax9QIAQQh1";

export const allowedMessageOrigin = REACT_APP_ALLOWED_MESSAGE_ORIGIN
    ? new RegExp(REACT_APP_ALLOWED_MESSAGE_ORIGIN, "i")
    : /^(https?:\/\/localhost:?\d*|https:\/\/.*\.bimplus\.net|https:\/\/login(?:-dev|-beta)?\.solibri.com)/i;

export const paddleProductNameWithTrial = REACT_APP_PADDLE_PRODUCT_NAME_WITH_TRIAL
    ? REACT_APP_PADDLE_PRODUCT_NAME_WITH_TRIAL
    : "Architectural Package Trial";

export const paddleProductNameWithoutTrial = REACT_APP_PADDLE_PRODUCT_NAME_WITHOUT_TRIAL
    ? REACT_APP_PADDLE_PRODUCT_NAME_WITHOUT_TRIAL
    : "Architectural Package";

export const paddleProductId = REACT_APP_PADDLE_PRODUCT_ID ? REACT_APP_PADDLE_PRODUCT_ID : "52772";

export const bimPlusAllowedOrigin = REACT_APP_BIMPLUS_ALLOWED_ORIGIN
    ? REACT_APP_BIMPLUS_ALLOWED_ORIGIN
    : "https://portal-stage.bimplus.net";
