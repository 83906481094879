import { AuthService } from "react-oauth2-pkce";
import { isInsideUi } from "utils/helperFunctions";

import {
    authBaseUrl,
    authClientIdEditor,
    authClientIdPlugin,
    authClientSecretEditor,
    authClientSecretPlugin,
} from "../constants";

const SCOPES = [
    "openid",
    "profile",
    "email",
    "https://apis.10duke.com/auth/openidconnect/user.properties",
    "https://apis.10duke.com/auth/openidconnect/organization",
];

const authService = isInsideUi()
    ? new AuthService({
          clientId: authClientIdPlugin,
          clientSecret: authClientSecretPlugin,
          location: window.location,
          logoutEndpoint: `${authBaseUrl}/user/oauth20/signout`,
          authorizeEndpoint: `${authBaseUrl}/user/oauth20/authz/editor-plugin-ui/plugin`,
          tokenEndpoint: `${authBaseUrl}/user/oauth20/token`,
          redirectUri: `${window.location.origin}/auth/inside/callback`,
          scopes: SCOPES,
      })
    : new AuthService({
          clientId: authClientIdEditor,
          clientSecret: authClientSecretEditor,
          location: window.location,
          logoutEndpoint: `${authBaseUrl}/user/oauth20/signout`,
          authorizeEndpoint: `${authBaseUrl}/user/oauth20/authz/editor-plugin-ui/editor`,
          tokenEndpoint: `${authBaseUrl}/user/oauth20/token`,
          redirectUri: `${window.location.origin}/auth/callback`,
          scopes: SCOPES,
      });

export default authService;
