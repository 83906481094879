import * as Sentry from "@sentry/react";
//import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

export const KEYS = {
    LAST_CHECK_ID: "LAST_CHECK_ID",
    CONSENT: "consent",
    TOURS_DISABLED: "tours-disabled",
    LICENSE_TOKEN: "solibri-inside-token",
    FINGERPRINT_ID: "fingerprintId",
    HOST_USER_ID: "solibri-inside-host-uid",
    HAS_RECENTLY_UPGRADED: (userId) => `hasUpgraded-${userId}`,
    ORGANIZATION_DETAILS: (userId) => `organizationDetails-${userId}`,
    ORGANIZATION_ID: (userId) => `organizationId-${userId}`,
};

export const setItem = (key, value) => {
    try {
        if (localStorage) {
            localStorage.setItem(key, value);
        }
    } catch (err) {
        Sentry.captureException(err);
    }
};

export const getItem = (key) => {
    try {
        if (localStorage) {
            return localStorage.getItem(key);
        }
        return null;
    } catch (err) {
        Sentry.captureException(err);
        return null;
    }
};

export const removeItem = (key) => {
    try {
        if (localStorage) {
            return localStorage.removeItem(key);
        }
    } catch (err) {
        Sentry.captureException(err);
    }
};

/* for receiving boolean(true) value stored in localStorage.
 *  localStorage stores all values as strings. */
export const getBoolean = (key) => {
    const value = getItem(key);

    // return always boolean. true only when value in localStorage is "true"
    return !(value === null || value !== "true");
};

export const getJSON = (key) => {
    return JSON.parse(getItem(key));
};
