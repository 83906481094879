export const ACTION_TYPES = {
    SET_USER_GROUP_LIST: "SET_USER_GROUP_LIST",
    SET_USERS_TO_ADD: "SET_USERS_TO_ADD",
    CREATE_USER_GROUP: "CREATE_USER_GROUP",
    UPDATE_USER_GROUP: "UPDATE_USER_GROUP",
    REMOVE_USER_GROUP: "REMOVE_USER_GROUP",
    SET_ORGANIZAION_COLLECTIONS: "SET_ORGANIZAION_COLLECTIONS",
    CREATE_ORGANIZATION_COLLECTION: "CREATE_ORGANIZATION_COLLECTION",
    UPDATE_ORGANIZATION_COLLECTION: "UPDATE_ORGANIZATION_COLLECTION",
    REMOVE_ORGANIZATION_COLLECTION: "REMOVE_ORGANIZATION_COLLECTION",
    SET_LOGGED_IN_USER: "SET_LOGGED_IN_USER",
    SET_NON_LOGGED_IN_USER: "SET_NON_LOGGED_IN_USER",
    SET_HAS_RECENTLY_UPGRADED: "SET_HAS_RECENTLY_UPGRADED",
    SET_ORDER_SUMMARY: "SET_ORDER_SUMMARY",

    /* PLUGIN ACTIONS: Starts here */
    SET_IS_LOADING: "SET_IS_LOADING",
    SET_IS_EMBEDDED: "SET_IS_EMBEDDED",
    SET_IS_DEBUG_MODE: "SET_IS_DEBUG_MODE",
    SET_IS_DISABLED: "SET_IS_DISABLED",
    SET_DISABLE_REASON: "SET_DISABLE_REASON",
    SET_CHECK_RESULT: "SET_CHECK_RESULT",
    SET_SELECTED_USER_GROUP: "SET_SELECTED_USER_GROUP",
    SET_HOST_APP_PARAMETERS: "SET_HOST_APP_PARAMETERS",
    /* PLUGIN ACTIONS: Ends here */
    SET_EDITOR_CHECKS_COLLECTION: "SET_EDITOR_CHECKS_COLLECTION",
};
