import axios from "./api";
import tenduke from "./auth/10DukeApi";
import { middlewareBaseURL } from "./constants";
import authService from "./auth";
import { handleApiErrors } from "./servicesHelpers";
import { displaySuccessToast } from "utils/toast";
import middleware from "./auth/MiddlewareApi";
import countryOptions from "../components/editor/web-shop/create-organization/data/countryOptions.json";
import { updateLoggedInUser } from "./loggedInUser";
import { isInsideUi } from "../utils/helperFunctions";
import { mapUserGroup } from "utils/mapper";
import * as Sentry from "@sentry/react";

export const getUserGroups = async () => {
    try {
        return await axios.get("/api/v2/user-groups");
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

let refreshPromise = null;
/**
 * Refresh the current access token and return true on success, otherwise false
 *
 * Only run one refresh promise at a time. Multiple calls to this function will wait for the same result.
 *
 * @returns {Promise<bool>}
 */
export const refreshAccessToken = async () => {
    if (refreshPromise && !refreshPromise.isDone) {
        return refreshPromise;
    }

    refreshPromise = (async () => {
        let currentTokens = authService.getAuthTokens();

        // currentTokens is an empty object if we are not logged in.
        if (!currentTokens || Object.keys(currentTokens).length === 0) {
            return false;
        }

        // 10 duke will respond with the property 'error' if something went wrong.
        if (currentTokens.error) {
            return false;
        }

        try {
            await authService.fetchToken(currentTokens.refresh_token, true);
        } catch (err) {
            handleApiErrors(err);
            return false;
        }

        currentTokens = authService.getAuthTokens();
        const success = !currentTokens.error;
        refreshPromise.isDone = true;
        return success;
    })();

    refreshPromise.isDone = false;
    return refreshPromise;
};

/**
 * Return the current access token, or clear local storage of tokens if there is an error.
 *
 * - If the token has not expired it is fetched from local storage.
 * - If the token has expired it is fetched from the auth server with a refresh token.
 *
 */
export const getAccessToken = async () => {
    let currentTokens = authService.getAuthTokens();

    // currentTokens is an empty object if we are not logged in.
    if (!currentTokens || Object.keys(currentTokens).length === 0) {
        return;
    }

    // 10 duke will respond with the property 'error' if something went wrong.
    if (currentTokens.error) {
        // logout(false) removes all auth data from local storage but does not log out sso session.
        await authService.logout(false);
        return;
    }

    const now = new Date().getTime();
    const refreshThresholdMilliSeconds = 120_000;
    const timeLeft = currentTokens.expires_at - now;
    if (timeLeft < refreshThresholdMilliSeconds) {
        const success = await refreshAccessToken();
        if (!success) {
            await authService.logout(false);
            return;
        }
        currentTokens = authService.getAuthTokens();
    }

    return currentTokens.access_token;
};

export const getIdToken = async () => {
    let currentTokens = authService.getAuthTokens();

    // currentTokens is an empty object if we are not logged in.
    if (!currentTokens || Object.keys(currentTokens).length === 0) {
        return null;
    }

    if (currentTokens.error) {
        return null;
    }

    return currentTokens.id_token;
};

export const hasActive10DukeSession = async (assertThisEmail) => {
    try {
        const response = await tenduke.get("/user/api/latest/user");
        if (!assertThisEmail) {
            return true;
        }

        return response.data.email === assertThisEmail;
    } catch (err) {
        // Only report an error if there actually is one.
        if (err.response.status !== 401 || err.response.data?.error !== "login_required") {
            handleApiErrors(err);
        }
        return false;
    }
};

export const getUserProfile = async () => {
    try {
        return await axios.get("/api/v2/user-profile");
    } catch (err) {
        if (err.response.status === 401) {
            authService.logout(false);
            return null;
        }

        handleApiErrors(err);
        return null;
    }
};

export const getAuthoringToolUserTosStatus = async () => {
    try {
        return await axios.get("/api/v2/authoring-tool-user/tos");
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

export const updateAuthoringToolUserTosStatus = async (accepted) => {
    try {
        return await axios.patch("/api/v2/authoring-tool-user/tos", { tosAccepted: accepted });
    } catch (err) {
        if (err.response.status === 401) {
            authService.logout(false);
            return null;
        }

        handleApiErrors(err);
        return null;
    }
};

export const getOrganizationPaymentProfileMiddleware = async (orgId) => {
    const idToken = await getIdToken();
    const accessToken = await getAccessToken();
    const paymentProfileUrl = `${middlewareBaseURL}v1/organizations/${orgId}/payment-profile`;
    const config = {
        headers: {
            Authorization: idToken,
            "X-Access-Token": accessToken,
        },
    };

    try {
        return await middleware.get(paymentProfileUrl, config);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

export const ACCOUNT_EXISTS_ERROR_MESSAGE = "user_name_reserved";
export const accountExists = (response) => response.data.error === ACCOUNT_EXISTS_ERROR_MESSAGE;

export const registerUser = async (userData) => {
    const { email, firstName, lastName, password, role, organization, marketingConsent, country } =
        userData;
    const countryName = countryOptions.find((c) => c.id === country).label;

    const body = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        companyName: organization,
        consentMarketing: marketingConsent,
        password: password,
        role: role,
        countryName: countryName,
        countryCode: country,
        acceptedInsideTOS: true,
    };

    try {
        return await middleware.post(`${middlewareBaseURL}v1/registerUsers`, body);
    } catch (err) {
        if (!accountExists(err.response)) {
            handleApiErrors(err);
        }

        return err.response;
    }
};

export const acceptTermsOfServiceInsideBackend = async () => {
    const data = {
        tosAccepted: true,
    };

    try {
        return await axios.patch("/api/v2/user-profile/terms-of-service", data);
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

export const migrate10DukeUserToSalesforce = async ({
    loggedInUser,
    formData,
    firstName,
    lastName,
}) => {
    const idToken = await getIdToken();
    const config = {
        headers: {
            Authorization: idToken,
        },
    };

    const { role, companyName, countryCode, termsOfServiceConsent, marketingConsent } = formData;

    const body = {
        id: loggedInUser.id,
        email: loggedInUser.email,
        firstName,
        lastName,
        companyName: companyName,
        role: role,
        countryCode: countryCode,
        acceptedInsideTOS: termsOfServiceConsent,
        consentMarketing: marketingConsent,
    };

    try {
        return await middleware.post(`${middlewareBaseURL}v1/migrateUsers`, body, config);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};
export const changePassword = async ({ currentPassword, newPassword }) => {
    const body = { currentPassword, newPassword };

    try {
        await tenduke.put("/user/api/latest/changePassword", body);
        displaySuccessToast("Password changed");
    } catch (err) {
        if (err.response.status !== 401) {
            handleApiErrors(err);
        }
        return err.response;
    }
};

export const getInvitationDetails = async (invitationToken, email) => {
    const payload = new URLSearchParams({
        token: invitationToken,
        email: email,
    }).toString();
    try {
        return await tenduke.post("/user/api/latest/invitations", payload);
    } catch (err) {
        if (err.response.status !== 404) {
            handleApiErrors(err);
        }
        return err.response;
    }
};

export const acceptInvitationMiddleware = async (userId, userEmail) => {
    const idToken = await getIdToken();
    const config = {
        headers: {
            Authorization: idToken,
        },
    };

    const payload = {
        userId: userId,
        email: userEmail,
    };

    try {
        return await middleware.post("v1/inviteAccept", payload, config);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

export const acceptInvitation10Duke = async (invitationToken, email) => {
    const payload = new URLSearchParams({
        token: invitationToken,
        email: email,
    }).toString();

    try {
        return await tenduke.put("/user/api/latest/invitations/accept", payload);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

export const resendEmailVerification = async () => {
    // 10 duke expects verificationUri even if it is not used, so I leave it empty.
    const payload = {
        verificationUri: "",
    };

    try {
        return await tenduke.post("/user/api/latest/emailVerifications", payload);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

/**
 * This gives some basic information for the user in the current session with 10 duke.
 * @returns {Promise<AxiosResponse<any>|*>}
 */
export const get10DukeAuthenticatedUser = async () => {
    try {
        return await tenduke.get("/user/api/latest/user");
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

export const get10DukeChallenge = async () => {
    try {
        const response = await tenduke.get(
            "/user/api/latest/sessions/authentications?remember=false&returnUser=false"
        );
        return {
            status: response.status,
            isVerify: false,
            isMultiFactorAuthentication: false,
        };
    } catch (err) {
        let isVerify = false;
        let isMultiFactorAuthentication = false;
        const response = err.response;
        if (response.status === 401 && response.headers["www-authenticate"]) {
            // www-authenticate is a url encoded json string with information on what has to be done
            // The current solution below is quite ugly where we don't make sense of the json, we just look for specific
            // strings. As we add support for more challenges we should maybe create a more robust solution.
            const authenticationChallenge = response.headers["www-authenticate"];

            if (authenticationChallenge.includes("authenticationMethod%22%3A%22totp%22")) {
                isMultiFactorAuthentication = true;
            } else if (
                authenticationChallenge.includes(
                    "authenticationMethod%22%3A%22emailVerification%22"
                )
            ) {
                isVerify = true;
            }
        } else {
            // This could be inside an iframe so we don't want to display an error toast.
            Sentry.captureException(err);
        }

        return {
            status: err.response.status,
            isVerify: isVerify,
            isMultiFactorAuthentication: isMultiFactorAuthentication,
        };
    }
};

export const verifyEmailAddress = async (verificationCode) => {
    const payload = {
        authenticationMethod: "emailVerification",
        verificationCode: verificationCode,
    };

    try {
        return await tenduke.post("/user/api/latest/sessions/authentications", payload);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

export const finalizeMultiFactorAuthentication = async (mfaCode) => {
    const payload = {
        authenticationMethod: "totp",
        oneTimePassword: mfaCode,
    };

    try {
        return await tenduke.post("/user/api/latest/sessions/authentications", payload);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

export const createUser = async (data) => {
    try {
        const response = await axios.post("/api/v2/user-profile", data);
        displaySuccessToast("User has been created.");
        return response;
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

export const getCollectionsOfOrganization = async () => {
    try {
        const response = await axios.get("/api/v2/organization/user-groups");
        return response.data;
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

export const createCollection = async (data) => {
    try {
        const response = await axios.post("/api/v2/user-groups", data);
        displaySuccessToast(`Collection ${data.name} has been created.`);
        return mapUserGroup(response.data);
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

export const updateCollection = async (id, data) => {
    try {
        const response = await axios.patch(`/api/v2/user-groups/${id}`, data);
        displaySuccessToast("Collection has been updated.");
        return response;
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

export const deleteCollection = async (id) => {
    try {
        const response = await axios.delete(`/api/v2/user-groups/${id}`);
        displaySuccessToast("Collection has been deleted.");
        return response;
    } catch (err) {
        handleApiErrors(err);
        return null;
    }
};

export const fetchUserInfo = async (
    dispatch,
    history,
    setLoading = () => {},
    onSuccess = () => {},
    onFailure = () => {}
) => {
    setLoading(true);

    if (!authService.isAuthenticated()) {
        const { pathname } = history.location;
        const hasActiveSession = await hasActive10DukeSession(null);
        if (
            hasActiveSession &&
            !pathname.includes("/register/verify") &&
            !pathname.includes("/multi-factor-auth")
        ) {
            history.push(isInsideUi() ? "/inside/login" : "/editor/login");
        }
        setLoading(false);
        onFailure();
        return;
    }

    const hasActiveSession = await hasActive10DukeSession(authService.getUser().email);

    if (!hasActiveSession) {
        await authService.logout(false);
        setLoading(false);
        onFailure();
        return;
    }

    const loggedInUser = await updateLoggedInUser(dispatch);

    if (!loggedInUser) {
        setLoading(false);
        onFailure();
        return;
    }

    onSuccess(loggedInUser);
};

export const resendInvitation = async (orgId, invitedEmail) => {
    const idToken = await getIdToken();
    const config = {
        headers: {
            Authorization: idToken,
        },
    };

    const body = {
        orgId: orgId,
        invitedEmail: invitedEmail,
    };

    try {
        return await middleware.post(`${middlewareBaseURL}v1/resendInvitation`, body, config);
    } catch (err) {
        Sentry.captureException(err);
        return err.response;
    }
};

export const updatePackages = async (updatePackageData) => {
    const {
        loggedInUser,
        organization,
        userList,
        packageId,
        newSeatCount,
        operation,
        subscriptionId,
    } = updatePackageData;
    const idToken = await getIdToken();
    const config = {
        headers: {
            Authorization: idToken,
        },
    };

    const body = {
        orgId: organization.id,
        adminId: loggedInUser.id,
        orgName: organization.name,
        subscriptionId: subscriptionId,
        packageId: packageId,
        operation: operation,
        seatQuantity: newSeatCount,
        userList: userList,
    };

    try {
        return await middleware.post(`${middlewareBaseURL}v1/updatePackages`, body, config);
    } catch (err) {
        handleApiErrors(err);
        return err.response;
    }
};

// Promotional API
export const getPromotion = async () => {
    try {
        return await axios.get("/api/v2/user-promotion");
    } catch (err) {
        return err.response;
    }
};

// Organization API
export const createOrganization = async (payload) => {
    const idToken = await getIdToken();
    const config = {
        headers: {
            Authorization: idToken,
        },
    };

    try {
        return await middleware.post("/v1/organizations", payload, config);
    } catch (err) {
        return err.response;
    }
};

export const updateOrganization = async (payload) => {
    const idToken = await getIdToken();
    const config = {
        headers: {
            Authorization: idToken,
        },
    };

    try {
        return await middleware.put("/v1/organizations/promotion", payload, config);
    } catch (err) {
        return err.response;
    }
};
