import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "react-oauth2-pkce";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import ErrorBoundary from "./components/common/ErrorBoundary";

import { theme } from "@solibri/solibri-components";
import Store from "./store";
import authService from "./services/auth";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import ToastNotification from "components/common/ToastNotification";
import CheckStore from "store/CheckContext";
import Loading from "components/common/Loading";
import { FeatureToggle } from "store/FeatureToggleContext";
import { RULE_TYPES } from "./constants/app";

const Editor = lazy(() => import("./components/editor/Editor"));
const PluginContainer = lazy(() => import("./components/plugin/PluginContainer"));
const Authentication = lazy(() => import("./components/common/Authentication/Authentication"));

const queryClient = new QueryClient();
const enabledFeatures = [
    RULE_TYPES.LOI.id,
    RULE_TYPES.CLEARANCE_DOOR.id,
    RULE_TYPES.CLEARANCE_WINDOW.id,
    RULE_TYPES.EXISTENCE.id,
    RULE_TYPES.NAMING_CONVENTION.id,
    RULE_TYPES.CLEARANCE_VERTICAL.id,
];

const Main = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <ErrorBoundary>
                        <AuthProvider authService={authService}>
                            <DndProvider backend={HTML5Backend}>
                                <Toaster
                                    position="top-center"
                                    gutter={16}
                                    toastOptions={{ duration: 5000 }}
                                >
                                    {(t) => <ToastNotification toast={t} />}
                                </Toaster>

                                <Store>
                                    <CheckStore>
                                        <FeatureToggle enabledFeatures={enabledFeatures}>
                                            <Router>
                                                <Suspense fallback={<Loading />}>
                                                    <Switch>
                                                        <Route path="/auth">
                                                            <Authentication />
                                                        </Route>
                                                        <Route path="/inside">
                                                            <PluginContainer />
                                                        </Route>
                                                        <Route path="/">
                                                            <Editor />
                                                        </Route>
                                                    </Switch>
                                                </Suspense>
                                            </Router>
                                        </FeatureToggle>
                                    </CheckStore>
                                </Store>
                            </DndProvider>
                        </AuthProvider>
                    </ErrorBoundary>
                </QueryClientProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default Main;
