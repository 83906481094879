import { Translation } from "react-i18next";
import { LOGIN_UI_IFRAME_ID } from "./constants";
import { tendukeBaseURL } from "../services/constants";

/**
 * Is the application running in the web ui?
 * @returns {boolean} - true if the application is in the web ui, otherwise not.
 */
export const isInsideUi = () => window.location.pathname.includes("/inside");

export const sortByKey = (arr, key) => {
    return arr.sort((a, b) =>
        typeof a[key] === "string" ? a[key].localeCompare(b[key]) : a[key] > b[key]
    );
};

/**
 *  filterObjectByKeyName - Removes the unneeded object key and the associated vale form the object returning a new object
 * @param objectToFilter - object with keys to filter
 * @param key    - key of the object to use in filtering
 * @return {{}}
 */
export const filterObjectByKeyName = (objectToFilter, key) => {
    const newObject = {};
    Object.keys(objectToFilter)
        .filter((d) => {
            return d !== key;
        })
        .forEach((item) => (newObject[item] = objectToFilter[item]));
    return newObject;
};

/**
 * getLabelWithCategoryGroup  - get a categoryGroup and label objects from a label key
 * @param labelName - label whose category is to be searched for
 * @param categoryList    - list of categories
 * @param labelList   - list of labels or label
 * @return {[object, object]}
 */
export const getLabelWithCategoryGroup = (labelName, categoryList, labelList) => {
    const desiredLabel = labelList.filter((i) => i.title === labelName)[0];
    const categoryGroup = categoryList.filter((cat) => cat.id === desiredLabel.categoryId)[0];
    return [desiredLabel, categoryGroup];
};

export const object2Array = (objects, withKey = true) => {
    return Object.keys(objects).map((objKey) =>
        withKey ? { [objKey]: objects[objKey] } : objects[objKey]
    );
};

/**
 * Search for an array with non-array content from the parent array's tail (END index)
 * used where content of each array is expected to have a common value e.g.
 * in target.criteriaList.data array where all arrays containing non-arrays should have the field 'Name'
 * returns the array at that level and the level where it was found e.g. given [2,[[8]]]
 * returns {result:[8], validChildLevel:2}
 * @param {array<array>} array
 * @param {number} level
 * @return {*|{result: *, validChildLevel: number}}
 */
export const findFirstArrayWithNonArrayContent = (array, level = 0) => {
    const result = array;
    const tail = array.length - 1;
    const entry = array[tail];
    if (Array.isArray(entry)) return findFirstArrayWithNonArrayContent(entry, level + 1);
    return { result, validChildLevel: level };
};

export const getTargetGroupName = (target) => {
    if (Array.isArray(target.criteriaList)) return null;
    //if criteriaList.data has complex arrays nesting use try resolving desired array
    const { result: criteriaList } = findFirstArrayWithNonArrayContent(target.criteriaList.data);
    if (criteriaList.length === 0) return null;
    const lastIndex = criteriaList.length - 1;
    if (!criteriaList[lastIndex].Name) {
        const parentIndex = 0;
        const innerLastIndex = criteriaList[parentIndex].length - 1;
        const parentCriteriaList = criteriaList[parentIndex];
        if (!parentCriteriaList[innerLastIndex] || !parentCriteriaList[innerLastIndex].Name)
            return null;
        return parentCriteriaList[innerLastIndex].Name;
    }
    return criteriaList[lastIndex].Name;
};

export const targetIsNotUsed = (targetId, ruleList) => {
    for (const ruleIndex in ruleList) {
        const rule = ruleList[ruleIndex];
        if (rule.obstacleIds.includes(targetId) || rule.targetIds.includes(targetId)) return false;
    }
    return true;
};

export const findFirst = (needle, haystack, key) => {
    for (const el in haystack) {
        if (key && needle === haystack[el][key]) return haystack[el];
        else if (needle === haystack[el]) return haystack[el];
    }
    return null;
};

export const array2Object = (objectArray) => {
    if (!objectArray?.length) return {};
    return objectArray.reduce((acc, obj) => {
        acc[Object.keys(obj)[0]] = Object.values(obj)[0];
        return acc;
    }, {});
};

export const isNumber = (val) => !isNaN(parseFloat(val) && isNaN(parseInt(val)));

export const isValidUrl = (urlString) => {
    try {
        new URL(urlString);
        return true;
    } catch (err) {
        return false;
    }
};

const VALID_LICENSE_NAMES = ["architect", "Architect", "architectural"];

export const hasArchitecturalLicense = (user) => {
    let hasAccess = false;

    if (user?.access?.licenses?.length) {
        const licenseNames = user?.access.licenses.map((license) => license.name);
        hasAccess = licenseNames.some((licenseName) => {
            return VALID_LICENSE_NAMES.includes(licenseName);
        });
    }
    return hasAccess;
};

export const hasOrganization = (user) => {
    return !!user?.organization?.id;
};

export const translateConstantValue = (key, defaultVal) => {
    return (
        <Translation>
            {(t, { i18n }) => {
                if (defaultVal) return <>{t(key, defaultVal)}</>;
                return <>{t(key)}</>;
            }}
        </Translation>
    );
};

export const convertToCamelCase = (str) => {
    // Split the string into an array of words
    const words = str.split(" ");
    if (words.length <= 1) return str;
    // Convert the first letter of each word to uppercase
    for (let i = 1; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    // Join the words back together with no spaces
    return words.join("");
};

// Update the locale of the login-ui iframe
export const updateIframeLocale = (locale) => {
    if (!locale) return;

    const iframe = document.getElementById(LOGIN_UI_IFRAME_ID);

    if (!iframe) return;

    const message = {
        type: "changeLocale",
        locale: locale,
    };

    iframe.contentWindow.postMessage(message, tendukeBaseURL);
};
