import { createContext, useState } from "react";
import PropTypes from "prop-types";

const CheckStore = ({ children }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [selectedUserGroup, setSelectedUserGroup] = useState("");
    const [rules, setRules] = useState([]);
    const [selectedRules, setSelectedRules] = useState([]);

    const clearForm = () => {
        setTitle("");
        setDescription("");
        setSelectedUserGroup("");
        setRules([]);
        setSelectedRules([]);
    };

    const data = {
        title,
        description,
        selectedUserGroup,
        rules,
        selectedRules,
        setTitle,
        setDescription,
        setSelectedUserGroup,
        setRules,
        setSelectedRules,
    };

    const value = { data, clearForm };

    return <CheckContext.Provider value={value}>{children}</CheckContext.Provider>;
};

CheckStore.propTypes = {
    children: PropTypes.node.isRequired,
};

export const CheckContext = createContext("Default Value");
export default CheckStore;
