import { createContext } from "react";
import PropTypes from "prop-types";

export const FeatureToggle = ({ children, enabledFeatures }) => {
    return (
        <FeatureToggleContext.Provider value={{ enabledFeatures }}>
            {children}
        </FeatureToggleContext.Provider>
    );
};

FeatureToggle.propTypes = {
    children: PropTypes.node.isRequired,
    enabledFeatures: PropTypes.arrayOf(PropTypes.number),
};

export const FeatureToggleContext = createContext({
    enabledFeatures: [],
});
