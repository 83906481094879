import axios from "axios";
import { baseURL, apiKey } from "./constants";
import { getAccessToken, refreshAccessToken } from "./user";
import HostAppParameters from "components/plugin/hostAppParameters";
import { KEYS, getItem } from "./storage";
import authService from "./auth";

const instance = axios.create({
    baseURL,
    timeout: 60000,
    headers: {
        "X-Api-Key": `Bearer Solibri ${apiKey}`,
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(async (config) => {
    // use license token in case of /v1 API endpoints
    const licenseToken = getItem(KEYS.LICENSE_TOKEN);
    if (config.url.indexOf("/api/v1/") !== -1 && licenseToken) {
        config.headers.Authorization = `Bearer ${licenseToken}`;
        return config;
    }

    const token = await getAccessToken();
    if (token) {
        config.__isTokenRequest = true;
        config.__token = token;
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        // Check if we have a host-app token, and replace the default X-Api-key with this token.
        const hostAppParameters = HostAppParameters.getHostAppParameters();
        if (hostAppParameters?.AuthenticationToken && hostAppParameters?.HostAppName) {
            config.headers[
                "X-Api-Key"
            ] = `Bearer ${hostAppParameters.HostAppName} ${hostAppParameters.AuthenticationToken}`;
        }
    }
    return config;
});

instance.interceptors.response.use(undefined, async (error) => {
    const requestConfig = error.config;
    // 401 on token request could mean that the token was refreshed during the call
    if (error.response.status === 401 && requestConfig.__isTokenRequest) {
        if (!requestConfig.__isRetry && (await getAccessToken()) !== requestConfig.__token) {
            // First failure of this request, and it was because the token sent was changed
            requestConfig.__isRetry = true;
            return instance(requestConfig);
        } else {
            // Fallback. Fail this request and force a refresh the tokens for future calls
            const success = await refreshAccessToken();
            if (!success) {
                await authService.logout(false);
            }
        }
    }
    return Promise.reject(error);
});

export default instance;
