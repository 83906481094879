import PropTypes from "prop-types";
import { ToastBar } from "react-hot-toast";
import { Typography } from "@solibri/solibri-components";

const ToastNotification = ({ toast }) => {
    return (
        <ToastBar toast={toast}>
            {({ icon, message }) => (
                <>
                    {icon}
                    <Typography size="medium" variant="body" text={message} />
                </>
            )}
        </ToastBar>
    );
};

ToastNotification.propTypes = {
    toast: PropTypes.shape({
        duration: PropTypes.number,
        createdAt: PropTypes.number,
        visible: PropTypes.bool,
        type: PropTypes.string,
        ariaProps: PropTypes.shape({ role: PropTypes.string, "aria-live": PropTypes.string }),
        message: PropTypes.string,
        pauseDuration: PropTypes.number,
        id: PropTypes.string,
        height: PropTypes.number,
    }),
};

export default ToastNotification;
