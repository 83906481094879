import axios from "axios";
import { tendukeBaseURL } from "../constants";

const tenduke = axios.create({
    baseURL: tendukeBaseURL,
    withCredentials: true,
    timeout: 60000,
    headers: {
        "X-10Duke-Client": "inside-ui",
    },
});

export default tenduke;
