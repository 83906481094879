import axios from "axios";
import { middlewareApiKey, middlewareBaseURL } from "../constants";

const middleware = axios.create({
    baseURL: middlewareBaseURL,
    timeout: 60000,
    headers: {
        "X-Api-Key": middlewareApiKey,
        "Content-Type": "application/json",
    },
});

export default middleware;
