import { v4 as uuidv4 } from "uuid";
import { DATA_TYPES } from "./criteria";

import { ReactComponent as HeartIcon } from "static/icons/heart-favorite.svg";
import { ReactComponent as BasicIcon } from "static/icons/basic-book.svg";
import { ReactComponent as OwnerIcon } from "static/icons/ownerhistory.svg";
import { ReactComponent as ListIcon } from "static/icons/list-property.svg";
import { ReactComponent as MaterialIcon } from "static/icons/book-material.svg";
import allplanGraphics from "static/images/allplanGraphic.png";
import vectorWorksGraphics from "static/images/vectorworksGraphic.png";
import archicadGraphics from "static/images/archicadGraphic.png";
import { ReactComponent as RepresentationIcon } from "static/icons/book-representation.svg";
import { ReactComponent as VerticalClearanceInfographic } from "static/images/vertical-clearance-infographic.svg";
import { ReactComponent as ClashDetectionInfographic } from "static/images/clash-detection-image.svg";
import { ReactComponent as DistanceBetweenComponentsInfographic } from "static/images/distance-between-components-image.svg";
import { COLOR } from "@solibri/solibri-components";

/* RULE TYPES */
export const RULE_TYPES = {
    LOI: {
        id: 0,
        name: "constants.app.ruleTypes.loi.name",
        description: "constants.app.ruleTypes.loi.description",
    },
    CLEARANCE_DOOR: {
        id: 1,
        name: "constants.app.ruleTypes.clearanceDoor.name",
        description: "constants.app.ruleTypes.clearanceDoor.description",
    },
    CLEARANCE_WINDOW: {
        id: 2,
        name: "constants.app.ruleTypes.clearanceWindow.name",
        description: "constants.app.ruleTypes.clearanceWindow.description",
    },
    EXISTENCE: {
        id: 3,
        name: "constants.app.ruleTypes.existence.name",
        description: "constants.app.ruleTypes.existence.description",
    },
    NAMING_CONVENTION: {
        id: 4,
        name: "constants.app.ruleTypes.namingConvention.name",
        description: "constants.app.ruleTypes.namingConvention.description",
    },
    CLEARANCE_VERTICAL: {
        id: 5,
        name: "constants.app.ruleTypes.clearanceVertical.name",
        description: "constants.app.ruleTypes.clearanceVertical.description",
        infographic: <VerticalClearanceInfographic />,
    },
    CLASH_DETECTION: {
        id: 6,
        name: "constants.app.ruleTypes.clashDetection.name",
        description: "constants.app.ruleTypes.clashDetection.description",
        infographic: <ClashDetectionInfographic />,
    },
    DISTANCE_BETWEEN_COMPONENTS: {
        id: 7,
        name: "constants.app.ruleTypes.distanceBetweenComponents.name",
        description: "constants.app.ruleTypes.distanceBetweenComponents.description",
        infographic: <DistanceBetweenComponentsInfographic />,
    },
    CSET: {
        id: 230,
        name: "constants.app.ruleTypes.cset.name",
        description: "constants.app.ruleTypes.cset.description",
        infographic: <DistanceBetweenComponentsInfographic />,
    },
};

/* TARGET TYPES */
export const TARGET_TYPE = {
    WALL: "Wall",
    DOOR: "Door",
    SPACE: "Space",
    WINDOW: "Window",
    SLAB: "Slab",
    COLUMN: "Column",
    STAIR: "Stair",
    RAMP: "Ramp",
    BEAM: "Beam",
};
const _TARGET_TYPES = [];
for (const key in TARGET_TYPE) {
    _TARGET_TYPES.push({
        id: uuidv4(),
        label: TARGET_TYPE[key],
    });
}
export const TARGET_TYPES = _TARGET_TYPES;

/* CHECK TYPES */
export const CHECK_TYPE = {
    BASIC: "basic",
    SMART: "smart",
    CSET: "cset",
    CUSTOM_CSET: "custom_cset",
};

/* OTHERS */
export const ATTRIBUTE_TYPES = {
    BASIC: "Basic",
    PSET: "PropertySet",
    CUSTOM_PSET: "Custom PropertySet",
    MATERIAL: "Material",
    OWNER: "OwnerHistory",
    REPRESENTATION: "Representation (Geometry)",
};

export const IFC_TYPE = {
    IDENTIFIER: {
        label: "IfcIdentifier",
        dataType: DATA_TYPES.STRING,
    },
    GLOBALLY_UNIQUER_ID: {
        label: "IfcGloballyUniqueId",
        dataType: DATA_TYPES.STRING,
    },
    INTEGER: {
        label: "IfcInteger",
        dataType: DATA_TYPES.INTEGER,
    },
    REAL: {
        label: "IfcReal",
        dataType: DATA_TYPES.REAL,
    },
    BOOLEAN: {
        label: "IfcBoolean",
        dataType: DATA_TYPES.BOOLEAN,
    },
    TEXT: {
        label: "IfcText",
        dataType: DATA_TYPES.STRING,
    },
    LABEL: {
        label: "IfcLabel",
        dataType: DATA_TYPES.STRING,
    },
    LOGICAL: {
        label: "IfcLogical",
        dataType: DATA_TYPES.BOOLEAN,
    },
    LENGTH_MEASURE: {
        label: "IfcLengthMeasure",
        dataType: DATA_TYPES.REAL,
    },
};

export const ROLES = [
    {
        id: 1,
        title: "Admin",
    },
    {
        id: 2,
        title: "Rule author",
    },
    {
        id: 3,
        title: "Model author",
    },
];

export const CLEARANCE_RULE_DEFAULT = {
    DOOR_FRONT_MESSAGE: "constants.app.clearanceRuleDefault.doorFrontMessage",
    DOOR_BACK_MESSAGE: "constants.app.clearanceRuleDefault.doorBackMessage",
    DOOR_BOTH_MESSAGE: "constants.app.clearanceRuleDefault.doorBothMessage",
    DOOR_OPERABILITY_MESSAGE: "constants.app.clearanceRuleDefault.doorOperabilityMessage",
    WINDOW_FRONT_MESSAGE: "constants.app.clearanceRuleDefault.windowFrontMessage",
    WINDOW_BACK_MESSAGE: "constants.app.clearanceRuleDefault.windowBackMessage",
    WINDOW_BOTH_MESSAGE: "constants.app.clearanceRuleDefault.windowBothMessage",
    WINDOW_OPERABILITY_MESSAGE: "constants.app.clearanceRuleDefault.windowOperabilityMessage",
};

export const IFC_SCHEMA_TITLES = {
    VERSION2X3: "IFC 2x3",
    VERSION4: "IFC 4",
    VERSION4POINT3: "IFC 4.3",
};
export const IFC_SCHEMA_VALUES = {
    VERSION2X3: "IFC2x3",
    VERSION4: "IFC4",
    VERSION4POINT3: "IFC4x3",
};

export const IFC_SCHEMA_OPTIONS = [
    {
        id: 0,
        title: IFC_SCHEMA_TITLES.VERSION2X3,
        value: IFC_SCHEMA_VALUES.VERSION2X3,
    },
    {
        id: 1,
        title: IFC_SCHEMA_TITLES.VERSION4,
        value: IFC_SCHEMA_VALUES.VERSION4,
    },
];

export const ATTRIBUTE_AND_PROPERTY_TYPES = {
    FAVORITE: "constants.app.attributeAndPropertyTypes.favourite",
    BASIC: "constants.app.attributeAndPropertyTypes.basic",
    OWNER: "constants.app.attributeAndPropertyTypes.owner",
    PSET: "constants.app.attributeAndPropertyTypes.pset",
    CUSTOM_PSET: "constants.app.attributeAndPropertyTypes.customPset",
    MATERIAL: "constants.app.attributeAndPropertyTypes.material",
    REPRESENTATION: "constants.app.attributeAndPropertyTypes.representation",
};

export const ATTRIBUTE_AND_PROPERTY_LIST = [
    {
        id: 2,
        title: ATTRIBUTE_AND_PROPERTY_TYPES.FAVORITE,
        icon: HeartIcon,
    },
    {
        id: 3,
        title: ATTRIBUTE_AND_PROPERTY_TYPES.BASIC,
        icon: BasicIcon,
    },
    {
        id: 4,
        title: ATTRIBUTE_AND_PROPERTY_TYPES.OWNER,
        icon: OwnerIcon,
    },
    {
        id: 5,
        title: ATTRIBUTE_AND_PROPERTY_TYPES.PSET,
        icon: ListIcon,
    },
    {
        id: 6,
        title: ATTRIBUTE_AND_PROPERTY_TYPES.CUSTOM_PSET,
        icon: ListIcon,
    },
    {
        id: 7,
        title: ATTRIBUTE_AND_PROPERTY_TYPES.MATERIAL,
        icon: MaterialIcon,
    },
    {
        id: 8,
        title: ATTRIBUTE_AND_PROPERTY_TYPES.REPRESENTATION,
        icon: RepresentationIcon,
    },
];
export const ATTRIBUTE_AND_PROPERTY_USED_LIST = [
    ATTRIBUTE_AND_PROPERTY_LIST[1],
    ATTRIBUTE_AND_PROPERTY_LIST[3],
];

export const REQUIREMENT_TYPES = Object.freeze({
    CRITERIA: 0,
    REQUIREMENT: 1,
    NAMING_CONVENTION: 2,
});

export const RULE_REQUIREMENT_TYPES = {
    CLEARANCE: "Clearance",
    OPERABILITY: "Operability",
    LOI: "LOI",
};

export const TERMS_OF_SERVICE_URL =
    "https://www.solibri.com/inside/solibri-inside-terms-and-conditions.pdf";
export const PRIVACY_POLICY_URL = "https://www.solibri.com/privacy/privacy-policy.pdf";

export const CUSTOMER_SUPPORT_URL =
    "https://help-inside.solibri.com/hc/en-us/requests/new?ticket_form_id=7858556779799";

export const LABEL_CATEGORIES = [
    {
        id: 1,
        title: "constants.app.labelCategories.labelCategoryNames.solibri",
        color: COLOR.TAG.GOLD.MAIN,
        textColor: COLOR.TAG.GOLD.DARK,
        colorName: "GOLD",
    },
    {
        id: 2,
        title: "constants.app.labelCategories.labelCategoryNames.project",
        color: COLOR.TAG.BLUE.MAIN,
        textColor: COLOR.TAG.BLUE.DARK,
        colorName: "BLUE",
    },
    {
        id: 3,
        title: "constants.app.labelCategories.labelCategoryNames.phases",
        color: COLOR.TAG.ORANGE.MAIN,
        textColor: COLOR.TAG.ORANGE.DARK,
        colorName: "ORANGE",
    },
    {
        id: 4,
        title: "constants.app.labelCategories.labelCategoryNames.useCase",
        color: COLOR.TAG.TEAL.MAIN,
        textColor: COLOR.TAG.TEAL.DARK,
        colorName: "TEAL",
    },
    {
        id: 5,
        title: "constants.app.labelCategories.labelCategoryNames.location",
        color: COLOR.TAG.PURPLE.MAIN,
        textColor: COLOR.TAG.PURPLE.DARK,
        colorName: "PURPLE",
    },
    {
        id: 6,
        title: "constants.app.labelCategories.labelCategoryNames.discipline",
        color: COLOR.TAG.PLUM.MAIN,
        textColor: COLOR.TAG.PLUM.DARK,
        colorName: "PLUM",
    },
    {
        id: 0,
        title: "constants.app.labelCategories.labelCategoryNames.other",
        color: COLOR.TAG.GREY.MAIN,
        textColor: COLOR.TAG.GREY.DARK,
        colorName: "GREY",
    },
];

export const AUTHORING_TOOLS_REGISTRATION = {
    archicad: {
        name: "Archicad",
        url: "https://help-inside.solibri.com/hc/en-us/articles/10621066469911-How-do-I-open-Solibri-Inside-in-my-design-tool-#h_01GK6R8F4Y5N17WS1K66XYWBAX",
        media: archicadGraphics,
    },
    allPlanBimplus: {
        name: "Allplan",
        url: "https://help-inside.solibri.com/hc/en-us/articles/10621066469911-How-do-I-open-Solibri-Inside-in-my-design-tool-#h_01GQ30XC8EC8SFK1A1E5JX8MY3",
        media: allplanGraphics,
    },
    vectorWorks: {
        name: "VectorWorks",
        url: "https://help-inside.solibri.com/hc/en-us/articles/10621066469911-How-do-I-open-Solibri-Inside-in-my-design-tool-#h_01GQEYT8DAD832SAYF565AKE47",
        media: vectorWorksGraphics,
    },
};

/* localization */
export const LANGUAGE_SELECTOR_OPTIONS = [
    { id: "EN", label: "English", enabled: true },
    { id: "DE", label: "Deutsch (Beta)", enabled: true },
    { id: "ES", label: "Español (Beta)", enabled: true },
    { id: "FR", label: "Français (Beta)", enabled: true },
    { id: "IT", label: "Italiano (Beta)", enabled: true },
    { id: "NL", label: "Nederlands (Beta)", enabled: true },
    { id: "PT", label: "Português (Beta)", enabled: true },
    { id: "JA", label: "日本語 (ベータ版)", enabled: true },
];

export const languageMapping = {
    English: "en-US",
    "Français (Beta)": "fr-FR",
    "日本語 (ベータ版)": "ja-JP",
    "Deutsch (Beta)": "de-DE",
    "Italiano (Beta)": "it-IT",
    "Español (Beta)": "es-ES",
    "Nederlands (Beta)": "nl-NL",
    "Português (Beta)": "pt-PT",
};

export const languageCodeMapping = {
    "en-US": "EN",
    "fr-FR": "FR",
    "fr-CH": "FR",
    "fr-CA": "FR",
    fr: "FR",
    "ja-JP": "JA",
    ja: "JA",
    "de-DE": "DE",
    de: "DE",
    it: "IT",
    "it-IT": "IT",
    es: "ES",
    "es-ES": "ES",
    "nl-NL": "NL",
    nl: "NL",
    "pt-PT": "PT",
    pt: "PT",
};

export const languageNameMapping = {
    "en-US": "English",
    "fr-FR": "Français (Beta)",
    "fr-CH": "Français (Beta)",
    "fr-CA": "Français (Beta)",
    fr: "Français (Beta)",
    "ja-JP": "日本語 (ベータ版)",
    ja: "日本語 (ベータ版)",
    "de-DE": "Deutsch (Beta)",
    de: "Deutsch (Beta)",
    it: "Italiano (Beta)",
    "it-IT": "Italiano (Beta)",
    es: "Español (Beta)",
    "es-ES": "Español (Beta)",
    "nl-NL": "Nederlands (Beta)",
    nl: "Nederlands (Beta)",
    "pt-PT": "Português (Beta)",
    pt: "Português (Beta)",
};
