import * as Sentry from "@sentry/react";
import axiosInstance from "../api";
import { allowedMessageOrigin, bimPlusAllowedOrigin } from "../constants";
import { logError } from "services/log";

const callParentWindowFunction = function (functionName, parameters = []) {
    window.parent.postMessage(
        JSON.stringify({ func: functionName, params: parameters }),
        getParentOrigin()
    );
};

const getParentOrigin = function () {
    const locationsAreDistinct = window.location !== window.parent.location;
    const parentOrigin = (
        (locationsAreDistinct ? document.referrer : document.location) || ""
    ).toString();
    let origin = "";

    if (parentOrigin) {
        origin = new URL(parentOrigin).origin;
    }

    const currentLocation = document.location;

    if (currentLocation.ancestorOrigins && currentLocation.ancestorOrigins.length) {
        origin = currentLocation.ancestorOrigins[0];
    } else if (origin === "") {
        origin = bimPlusAllowedOrigin;
    }

    return origin.match(allowedMessageOrigin) !== null ? origin : null;
};

// This to tell if the ui is in an authoring tool
export const isEmbedded = () => window.HostApp || (window.top !== window.self && getParentOrigin());

const HostApp =
    window.HostApp ??
    (isEmbedded()
        ? {
              GetHostAppParameters: () => {
                  // Still need to define the way to capture the values sent from the HostApp
                  callParentWindowFunction("GetHostAppParameters");
              },
              OpenInBrowser: (url) => {
                  window.open(url, "_blank");
              },
              ResultSelectionChanged: (data) => {
                  callParentWindowFunction("ResultSelectionChanged", [data]);
              },
              ResultHighlightChanged: (data) => {
                  callParentWindowFunction("ResultHighlightChanged", [data]);
              },
              StartCheckingProcess: () => {
                  callParentWindowFunction("StartCheckingProcess");

                  return 0;
              },
              StartModelDataTransfer: () => {
                  callParentWindowFunction("StartModelDataTransfer");

                  return 0;
              },
              SendModelDataRequest: (query) => {
                  callParentWindowFunction("SendModelDataRequest", [query]);
              },
              EndModelDataTransfer: () => {
                  callParentWindowFunction("EndModelDataTransfer");
              },
              SubmitMetrics: (metrics) => {
                  callParentWindowFunction("SubmitMetrics", [metrics]);
              },
              SendMetrics: (metrics) => {
                  callParentWindowFunction("SendMetrics", [metrics]);
              },
              NotifyStateChange: (state) => {
                  callParentWindowFunction("NotifyStateChange", [state]);
              },
              CreateIssue: (data) => {
                  callParentWindowFunction("CreateIssue", [data]);
              },
              NotifyError: (error) => {
                  callParentWindowFunction("NotifyError", [error]);
              },
          }
        : {
              GetHostAppParameters: () => {},
              OpenInBrowser: () => {},
              ResultSelectionChanged: (data) => {},
              StartCheckingProcess: () => {
                  console.info("HostApp", "StartCheckingProcess");
                  return 0;
              },
              StartModelDataTransfer: () => {
                  console.info("HostApp", "StartModelDataTransfer");
                  return 0;
              },
              SendModelDataRequest: (query) =>
                  console.info("HostApp", "SendModelDataRequest", query),
              EndModelDataTransfer: () => console.info("HostApp", "EndModelDataTransfer"),
              SubmitMetrics: (metrics) => console.info("HostApp", "SubmitMetrics", metrics),
              SendMetrics: (metrics) => console.info("HostApp", "SendMetrics", metrics),
              NotifyStateChange: (state) => console.info("HostApp", "NotifyStateChange", state),
              CreateIssue: (data) => console.info("HostApp", "CreateIssue", data),
              NotifyError: (error) => console.info("HostApp", "NotifyError", error),
          });

export const getHostAppParameters = async () => {
    try {
        const result = await HostApp.GetHostAppParameters();
        return result ? result : null;
    } catch (err) {
        logError(err);
        return null;
    }
};

export const openInBrowser = (url) => {
    try {
        HostApp.OpenInBrowser(url);
    } catch (err) {
        logError(err);
    }
};

export const selectElements = (data) => {
    try {
        HostApp.ResultSelectionChanged(data);
    } catch (err) {
        logError(err);
    }
};

export const highlightSelected = (data) => {
    try {
        HostApp.ResultHighlightChanged(data);
    } catch (err) {
        logError(err);
    }
};

/* Version 2.0 Methods: Starts Here */
export const isHostReady = async () => {
    try {
        const err = await HostApp.StartCheckingProcess();
        return err === 0;
    } catch (err) {
        logError(err);
        return 1;
    }
};

export const startModelDataTransfer = async () => {
    try {
        const err = await HostApp.StartModelDataTransfer();
        return err === 0;
    } catch (err) {
        logError(err);
        return 1;
    }
};

export const sendModelDataRequest = (query) => {
    try {
        HostApp.SendModelDataRequest(query);
    } catch (err) {
        logError(err);
    }
};

export const endModelDataRequest = () => {
    try {
        HostApp.EndModelDataTransfer();
    } catch (err) {
        logError(err);
    }
};

export const cancelCheckingProcess = (cause) => {
    try {
        HostApp.CancelCheckingProcess(cause);
    } catch (err) {
        logError(err);
    }
};

export const cancelJob = async (id) => {
    try {
        const response = await axiosInstance.delete(`/api/v1/jobs/checks/${id}`);
        return { success: true, data: response.data };
    } catch (err) {
        logError(err);
        return { success: false };
    }
};

export const submitMetrics = async (metrics) => {
    try {
        HostApp.SubmitMetrics(metrics);
    } catch (err) {
        logError(err);
    }
};

export const sendMetrics = async (metrics) => {
    try {
        HostApp.SendMetrics(metrics);
    } catch (err) {
        logError(err);
    }
};

export const notifyStateChange = (state) => {
    try {
        HostApp.NotifyStateChange(state);
    } catch (err) {
        logError(err);
    }
};

export const createIssues = async (data) => {
    try {
        const response = await axiosInstance.post(
            "/api/v2/bcf/issues?bcf-only=true&bcf-version=2.1",
            data
        );

        if (response?.data?.base64EncodedBCF) {
            HostApp.CreateIssue(response.data.base64EncodedBCF);
            return { success: true };
        }

        return { success: false };
    } catch (err) {
        logError(err);
        return { success: false };
    }
};

export const logErrorToHost = (error) => {
    try {
        HostApp.NotifyError(error);
    } catch (err) {
        Sentry.captureException(error);
    }
};
/* Version 2.0 Methods: Ends Here */
