import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import Main from "./Main";
import "./static/fonts/fonts.css";
import "./i18n";
import { getChromeVersion } from "./utils/detectBrowser";

// We only track errors in production to save some quota in Sentry
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://d632af7c65c84936b5ae90dcbba3ecef@o783479.ingest.sentry.io/5956765",
        tracesSampleRate: 1.0,
        environment: "production",
        beforeSend(event, hint) {
            // We need to limit the amount of errors we send to Sentry from Vectroworks
            // Add some samplerate to Vectorworks chromium versions, so we don't get clocked up.
            // The versions are based no data from previous Sentry errors.
            const vectorworksChromiomuMajorVersions = [84, 89, 104];
            const vectorWorksSampleRate = 0; // TODO: Change to 0.1 or something when we have fixed the errors happening on page load for all users, even non-logged in ones.
            const chromeVersion = getChromeVersion();
            if (
                chromeVersion &&
                vectorworksChromiomuMajorVersions.includes(chromeVersion) &&
                Math.random() > vectorWorksSampleRate // This is always the case, so we don't send any errors from Vectorworks
            )
                return null;
            // See Sentry docs about beforeSend https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-
            // Hide an error that happens only on old Vectorworks version. See https://github.com/Solibri/solibri-cloud/issues/6713
            if (hint.originalException.message.includes("http://crbug.com/490015")) return null;
            return event;
        },
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
