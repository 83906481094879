import { useTranslation, Trans } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Link } from "@mui/material";

import { Typography } from "@solibri/solibri-components";
import plugAndSocket from "static/icons/plug-and-socket.svg";
import cable from "static/icons/cable.svg";
import { isInsideUi } from "utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        margin: "2px",
    },
    heading: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(1),
    },
    errorMessage: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },
    button: {
        marginBottom: theme.spacing(4),
    },
    image: {
        height: "68px",
        width: "236px",
    },
    leftImage: {
        width: "calc((100% - 236px) / 2)",
        height: "7px",
        paddingBottom: "38px",
        [theme.breakpoints.down("466")]: {
            display: "none",
        },
    },
    rightImage: {
        width: "calc((100% - 236px) / 2)",
        height: "7px",
        paddingBottom: "25px",
        [theme.breakpoints.down("466")]: {
            display: "none",
        },
    },
    fillContainer: {
        height: "315px",
    },
    link: {
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.text.link,
        textDecorationColor: theme.palette.text.link,
        cursor: "pointer",
        marginBottom: theme.spacing(4),
    },
}));

const ExceptionPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const goToHome = () => {
        window.open(
            `${window.location.origin}${isInsideUi() ? "/inside" : "/editor/checks"}`,
            "_self"
        );
    };

    return (
        <div className={classes.content}>
            <Typography
                text={t("common.exceptionPage.text")}
                size="medium"
                variant="headline"
                component="h1"
                className={classes.heading}
            />
            <Typography
                text={t("common.exceptionPage.errorInfo")}
                size="large"
                variant="body"
                className={classes.errorMessage}
            />
            <Trans i18nKey={t("common.exceptionPage.home")}>
                <Link className={classes.link} onClick={() => goToHome()}>
                    {t("common.exceptionPage.home")}
                </Link>
            </Trans>
            <div>
                <img
                    src={cable}
                    alt={t("common.exceptionPage.imgAlt1")}
                    className={classes.leftImage}
                />
                <img
                    src={plugAndSocket}
                    alt={t("common.exceptionPage.imgAlt2")}
                    className={classes.image}
                />
                <img
                    src={cable}
                    alt={t("common.exceptionPage.imgAlt3")}
                    className={classes.rightImage}
                />
            </div>
            <div className={classes.fillContainer} />
        </div>
    );
};

export default ExceptionPage;
