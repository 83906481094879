import * as Sentry from "@sentry/react";
import findWhere from "underscore/modules/findWhere.js";
import indexOf from "underscore/modules/indexOf.js";
import { CHECK_TYPE } from "constants/app";

export const isInvalidString = (str) => !str || str.length === 0;

export const deepClone = (list) => {
    try {
        return JSON.parse(JSON.stringify(list));
    } catch (err) {
        Sentry.captureException(err);
        return list;
    }
};

export const getObjectIndex = (list, props) => {
    try {
        const item = findWhere(list, props);
        if (item) {
            const index = indexOf(list, item);
            return index;
        }
        return -1;
    } catch (err) {
        return -1;
    }
};

export const sortList = (list, key, isDesc) => {
    try {
        return list.sort((a, b) => {
            let aValue = a[key];
            let bValue = b[key];
            if (typeof aValue === "string") aValue = aValue.toLowerCase();
            if (typeof bValue === "string") bValue = bValue.toLowerCase();

            if (isDesc) return aValue > bValue ? -1 : 1;
            return aValue > bValue ? 1 : -1;
        });
    } catch (err) {
        Sentry.captureException(err);
        return [];
    }
};

export const sortByDate = (list) => {
    return list
        .sort(function (a, b) {
            return new Date(a.createdAt) - new Date(b.createdAt);
        })
        .reverse();
};

export const isValidEmail = (email) => {
    if (email.trim().length === 0) return false;

    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const filterInvitationEmails = (userEmails, existingUsers = []) => {
    if (!userEmails.length) return [];

    // filter out invalid emails
    let emails = userEmails && userEmails.filter((email) => isValidEmail(email));

    // filter out duplicated emails
    emails = emails
        .filter((email, index, arr) => arr.indexOf(email) === index)
        .filter((email) => !existingUsers.find((u) => u.email === email));

    return emails;
};

export const stringifyJSON = (data) => {
    try {
        return JSON.stringify(data);
    } catch (err) {
        return "";
    }
};

export const isDuplicate = (list, title) => {
    return list.some((el) => el.title.toLowerCase() === title.toLowerCase());
};

// Only use with objects with id property
export const listToObject = (list) =>
    list.reduce((total, current) => {
        total[current.id] = current;
        return total;
    }, {});

export const arrayOfStringsToStringWithAnd = (arrayOfStrings) => {
    const stringWithComma = arrayOfStrings.join(", ");
    const lastIndex = stringWithComma.lastIndexOf(", ");
    const outputString =
        arrayOfStrings.length > 1
            ? `${stringWithComma.slice(0, lastIndex)} and ${stringWithComma.slice(lastIndex + 2)}`
            : arrayOfStrings[0];
    return outputString;
};

export const schemaValuestoIndices = (schemaValues, schemaOptions) => {
    if (!schemaValues?.length) {
        return [];
    }
    const optionsValues = schemaOptions.map((s) => s.value);
    return schemaValues.map((value) => optionsValues.indexOf(value));
};

export const schemaIndicesToTitle = (selectedSchemaIndices, schemaOptions) => {
    if (selectedSchemaIndices.length === 0) {
        return [];
    }
    return selectedSchemaIndices.map((schemaIndex) =>
        schemaOptions[schemaIndex].title.toLowerCase().replace(/\s/g, "")
    );
};

export const targetsToString = (targets) => {
    return targets.map((t) => t.title).join(", ");
};

const normalizedSchemaTitles = (schemaVersions) => {
    if (!schemaVersions) {
        return [];
    }
    return schemaVersions.map((schemaTitle) => schemaTitle.toLowerCase().replace(/\s/g, ""));
};

export const incompatibleSelectedTargets = (targetsList, selectedTargetIds, schemaVersions) => {
    if (selectedTargetIds.length === 0 || schemaVersions.length === 0) {
        return [];
    }
    return targetsList.filter(
        (target) =>
            selectedTargetIds.includes(target.id) &&
            !schemaVersions.some((schema) =>
                normalizedSchemaTitles(target.criteriaList?.compatibleIfcVersions).includes(schema)
            )
    );
};

export const getUsersWithActiveSubscription = (users, productId) => {
    return (
        users?.filter((user) =>
            user.packages?.some((p) => !p?.validUntil && p.planId === productId)
        ) ?? []
    );
};

export const getNewSeatCount = (org, userList, productId, operation) => {
    const currentActiveUsers = getUsersWithActiveSubscription(org.users, productId);
    if (operation === "ADD") {
        return currentActiveUsers.length + userList.length;
    }
    if (operation === "REMOVE") {
        return currentActiveUsers.length - userList.length;
    }
};

const getUserPackages = (user) => {
    if (!user?.packages) {
        return [];
    }
    return user.packages;
};

export const getUpdatedUsersAfterUpdatePackage = (
    allUsers,
    targetedUsers,
    productId,
    operation,
    nextPaymentDate = undefined
) => {
    const targetedUserIds = targetedUsers.map((user) => user.userId);
    if (operation === "ADD") {
        const updatedUsers = allUsers.map((user) => ({
            ...user,
            packages: targetedUserIds.includes(user.id)
                ? [...getUserPackages(user), { planId: productId }]
                : getUserPackages(user),
        }));

        return updatedUsers;
    }
    if (operation === "REMOVE") {
        const updatedUsers = allUsers.map((user) => ({
            ...user,
            packages: targetedUserIds.includes(user.id)
                ? getUserPackages(user).map((pkg) => {
                      if (pkg.planId === productId) {
                          return { ...pkg, validUntil: nextPaymentDate };
                      }
                      return pkg;
                  })
                : getUserPackages(user),
        }));
        return updatedUsers;
    }
};

export const isEmptyCheck = (check) => {
    // cset checks don't have any rules or labels
    return (
        check.checkType !== CHECK_TYPE.CUSTOM_CSET &&
        check.checkType !== CHECK_TYPE.CSET &&
        !check.ruleIds?.length &&
        !check.labelIds?.length
    );
};

export function hasDuplicates(list) {
    return new Set(list).size !== list.length;
}

export const getEmailsFromString = (emailString) =>
    emailString
        .replace(/ /g, "")
        .split(",")
        .filter((email) => !!email.length);

export const validateEmails = (emails, users) => {
    if (!emails.length) {
        return "At least one email is required";
    }

    const includesInvalidEmails = emails.some((email) => !isValidEmail(email));

    if (includesInvalidEmails) {
        return "Invalid email format.";
    }

    const emailsAlreadyAdded = users.some((user) => emails.includes(user.email));
    if (emailsAlreadyAdded) {
        return "At least one email already exists in the organization.";
    }

    const duplicateEmailsExist = hasDuplicates(emails);
    if (duplicateEmailsExist) {
        return "Two identical emails were added.";
    }

    const moreThanTenEmails = emails.length > 10;
    if (moreThanTenEmails) {
        return "Only 10 emails can be added at a time.";
    }

    return null;
};
