import { mapUserGroupList } from "utils/mapper";
import { deepClone, getObjectIndex } from "utils";
import { ACTION_TYPES } from "./actionTypes";

const Reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_USER_GROUP_LIST: {
            const userGroupList = mapUserGroupList(action.payload);
            return { ...state, userGroupList };
        }
        case ACTION_TYPES.SET_ORGANIZAION_COLLECTIONS: {
            const organizationCollections = mapUserGroupList(action.payload);
            return { ...state, organizationCollections };
        }
        /* USERS & TEAMS */

        case ACTION_TYPES.SET_USERS_TO_ADD: {
            const usersToAdd = action.payload;
            return { ...state, usersToAdd };
        }

        case ACTION_TYPES.CREATE_USER_GROUP: {
            const { userGroupList } = state;
            return {
                ...state,
                userGroupList: [action.payload].concat(userGroupList),
            };
        }
        case ACTION_TYPES.CREATE_ORGANIZATION_COLLECTION: {
            const { organizationCollections } = state;
            return {
                ...state,
                organizationCollections: [action.payload].concat(organizationCollections),
            };
        }
        case ACTION_TYPES.UPDATE_USER_GROUP: {
            const { userGroupList } = state;
            const newList = deepClone(userGroupList);
            const { userGroupId, newValues } = action.payload;
            const index = getObjectIndex(newList, { id: userGroupId });
            if (index !== -1) {
                const itemToUpdate = newList[index];
                newList[index] = {
                    ...itemToUpdate,
                    ...newValues,
                };
            }
            return {
                ...state,
                userGroupList: newList,
            };
        }

        case ACTION_TYPES.UPDATE_ORGANIZATION_COLLECTION: {
            const { organizationCollections } = state;
            const newList = deepClone(organizationCollections);
            const { collectionId, newValues } = action.payload;
            const index = getObjectIndex(newList, { id: collectionId });
            if (index !== -1) {
                const itemToUpdate = newList[index];
                newList[index] = {
                    ...itemToUpdate,
                    ...newValues,
                };
            }
            return {
                ...state,
                organizationCollections: newList,
            };
        }
        case ACTION_TYPES.REMOVE_USER_GROUP: {
            const { userGroupList } = state;
            const { userGroupId } = action.payload;
            const newList = deepClone(userGroupList).filter((u) => u.id !== userGroupId);
            return {
                ...state,
                userGroupList: newList,
            };
        }
        case ACTION_TYPES.REMOVE_ORGANIZATION_COLLECTION: {
            const { organizationCollections } = state;
            const { collectionId } = action.payload;
            const newList = deepClone(organizationCollections).filter((c) => c.id !== collectionId);
            return {
                ...state,
                organizationCollections: newList,
            };
        }
        case ACTION_TYPES.SET_LOGGED_IN_USER: {
            return {
                ...state,
                loggedInUser: action.payload,
            };
        }

        case ACTION_TYPES.SET_NON_LOGGED_IN_USER: {
            return {
                ...state,
                nonLoggedIngUser: action.payload,
            };
        }

        case ACTION_TYPES.SET_ORDER_SUMMARY: {
            return {
                ...state,
                orderSummary: action.payload,
            };
        }

        /* PLUGIN REDUCERS: Starts Here */
        case ACTION_TYPES.SET_IS_LOADING: {
            return {
                ...state,
                plugin: {
                    ...state.plugin,
                    isLoading: action.payload,
                },
            };
        }
        case ACTION_TYPES.SET_IS_EMBEDDED: {
            return {
                ...state,
                plugin: {
                    ...state.plugin,
                    isEmbedded: action.payload,
                },
            };
        }
        case ACTION_TYPES.SET_IS_DEBUG_MODE: {
            return {
                ...state,
                plugin: {
                    ...state.plugin,
                    isDebugMode: action.payload,
                },
            };
        }
        case ACTION_TYPES.SET_IS_DISABLED: {
            return {
                ...state,
                plugin: {
                    ...state.plugin,
                    isDisabled: action.payload,
                },
            };
        }
        case ACTION_TYPES.SET_CHECK_RESULT: {
            return {
                ...state,
                plugin: {
                    ...state.plugin,
                    checkResult: action.payload,
                },
            };
        }
        case ACTION_TYPES.SET_SELECTED_USER_GROUP: {
            return {
                ...state,
                plugin: { ...state.plugin, selectedUserGroup: action.payload },
            };
        }
        case ACTION_TYPES.SET_HOST_APP_PARAMETERS: {
            return {
                ...state,
                plugin: {
                    ...state.plugin,
                    hostAppParameters: action.payload,
                },
            };
        }

        case ACTION_TYPES.SET_EDITOR_CHECKS_COLLECTION: {
            return {
                ...state,
                selectedEditorCheck: action.payload,
            };
        }
        /* PLUGIN REDUCERS: Ends Here */

        default:
            return state;
    }
};

export default Reducer;
