export const SOLIBRI_HELP_CENTER = "https://help-inside.solibri.com/";
export const SOLIBRI_INSIDE_WEBSITE = "https://www.solibri.com/inside";
export const LOGIN_UI_IFRAME_ID = "solibri-cloud-login-ui";

export const PLUGIN_MIN_HEIGHT_FOR_GIFS = 525;

export const ACCEPTED_DEFAULT_COLLECTION_NAMES = ["My first collection", "Default collection"];

export const emailRequired = "Email is required";
export const organizationRequired = "Name of organization is required";
export const industryRequired = "Industry is required";
export const numberOfEmployeesRequired = "Number of employees is required";
export const countryRequired = "Country is required";
