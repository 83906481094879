import { format, parseISO } from "date-fns";
import { RULE_TYPES } from "constants/app";

export const mapRule = (el) => ({
    id: el.id,
    ruleType: Object.values(RULE_TYPES).find((type) => type.id === el.ruleType),
    title: el.name,
    description: el.description ? el.description : "",
    labelIds: el.labelIds ? el.labelIds : [],
    targetIds: el.targetIds ? el.targetIds : [],
    obstacleIds: el.obstacleIds ? el.obstacleIds : [],
    requirements: el.requirements ? el.requirements : [],
    ownerId: el.creatorId.toLowerCase(),
    createdAt: format(parseISO(el.createdAt), "dd/MM/yyyy"),
    organizationId: el.organizationId ?? null,
});

export const mapTarget = (el) => ({
    id: el.id,
    title: el.name,
    criteriaList: el.criteria
        ? el.criteria?.compatibleIfcVersions
            ? el.criteria
            : {
                  ...el.criteria,
                  compatibleIfcVersions: [],
              }
        : [],
    ownerId: el.creatorId.toLowerCase(),
    createdAt: format(parseISO(el.createdAt), "dd/MM/yyyy"),
    organizationId: el.organizationId ?? null,
});

export const mapLabel = (el) => ({
    id: el.id,
    title: el.name,
    description: el.description ? el.description : "",
    categoryId: el.category,
    ownerId: el.creatorId.toLowerCase(),
    createdAt: format(parseISO(el.createdAt), "dd/MM/yyyy"),
});

export const mapUserGroup = (userGroup) => {
    const userIds = userGroup.users?.map((user) => user.id) ?? [];

    return {
        id: userGroup.id,
        title: userGroup.name,
        description: userGroup.description,
        userIds,
    };
};

export const mapUserGroupList = (data) => {
    return data.map((el, index) => mapUserGroup(el, index));
};
