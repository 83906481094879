const HostAppParameters = {
    params: null,
    setHostAppParameters(parameters) {
        this.params = parameters;
    },
    getHostAppParameters() {
        return this.params;
    },
};

export default HostAppParameters;
