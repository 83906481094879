import { Component } from "react";
import PropTypes from "prop-types";
import ExceptionPage from "./ExceptionPage";
import { logError } from "services/log";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        logError(error);
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logError(error);
        logError(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ExceptionPage />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
};

export default ErrorBoundary;
