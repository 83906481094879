import * as Sentry from "@sentry/react";
import { logErrorToHost } from "./archicad/index";
import { isInsideUi } from "utils/helperFunctions";

const isEmpty = (error) => {
    try {
        // null OR empty string OR "{}"
        if (!error || error === "{}") {
            return true;
        }

        // empty JSON object
        if (typeof error === "object" && Object.keys(error).length === 0) {
            return true;
        }

        // empty array
        if (Array.isArray(error) && error.length === 0) {
            return true;
        }

        return false;
    } catch (err) {
        return false;
    }
};

export const logError = (error) => {
    if (isEmpty(error)) {
        return;
    }

    if (isInsideUi()) {
        logErrorToHost(error);
    }
    Sentry.captureException(error);
};
