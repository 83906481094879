export const pluckValueByKey = (objArr, key, isValue = true, isObject = false) =>
    objArr.map((v) => {
        if (isValue && !isObject) return v[key];
        return { [key]: v[key] };
    });

export const DEFAULT_IFC_TYPE = {
    id: 0,
    name: "IfcProduct",
};

export const DATA_TYPES = {
    REAL: "number",
    INTEGER: "bigint",
    STRING: "string",
    BOOLEAN: "boolean",
    REGEX: "regex",
    ENUM: "enum",
    BINARY: "binary",
    IFC_DATE: "IfcDate",
    IFC_TIME: "IfcTime",
    IFC_DURATION: "IfcDuration",
};

export const INPUT_OPTIONS = [
    {
        id: 1,
        label: "constants.criteria.inputOptions.isDefined.label",
        hasInput: false,
        opposite: "constants.criteria.inputOptions.isDefined.opposite",
    },
    {
        id: 2,
        label: "constants.criteria.inputOptions.isNotDefined.label",
        hasInput: false,
        opposite: "constants.criteria.inputOptions.isNotDefined.opposite",
    },
    {
        id: 3,
        label: "constants.criteria.inputOptions.contains.label",
        hasInput: true,
        opposite: "constants.criteria.inputOptions.contains.opposite",
    },
    {
        id: 4,
        label: "constants.criteria.inputOptions.matches.label",
        hasInput: true,
        opposite: "constants.criteria.inputOptions.matches.opposite",
    },
    {
        id: 5,
        label: "constants.criteria.inputOptions.regex.label",
        hasInput: true,
        opposite: "constants.criteria.inputOptions.regex.opposite",
    },
    {
        id: 6,
        label: "constants.criteria.inputOptions.isOneOf.label",
        hasOptions: true,
        hasInput: false,
        options: [],
        opposite: "constants.criteria.inputOptions.isOneOf.opposite",
    },
    {
        id: 7,
        label: "constants.criteria.inputOptions.true.label",
        hasInput: false,
        opposite: "constants.criteria.inputOptions.true.opposite",
    },
    {
        id: 8,
        label: "constants.criteria.inputOptions.false.label",
        hasInput: false,
        opposite: "constants.criteria.inputOptions.false.opposite",
    },
    {
        id: 9,
        label: "==",
        hasInput: true,
        opposite: "!=",
    },
    {
        id: 10,
        label: "!=",
        hasInput: true,
        opposite: "==",
    },
    {
        id: 11,
        label: ">",
        hasInput: true,
        opposite: "<",
    },
    {
        id: 12,
        label: ">=",
        hasInput: true,
        opposite: "<=",
    },
    {
        id: 13,
        label: "<",
        hasInput: true,
        opposite: ">",
    },
    {
        id: 14,
        label: "<=",
        hasInput: true,
        opposite: ">=",
    },
    {
        id: 15,
        label: "constants.criteria.inputOptions.isIn.label",
        hasInput: false,
        hasOptions: true,
        opposite: "constants.criteria.inputOptions.isIn.opposite",
    },
    {
        id: 16,
        label: "constants.criteria.inputOptions.isNotIn.label",
        hasInput: false,
        hasOptions: true,
        opposite: "constants.criteria.inputOptions.isNotIn.opposite",
    },
];

export const INPUT_OPTIONS_PREDEFINED_ENUM_OPTIONS = [
    { id: 1, label: "constants.criteria.inputOptionsPredefinedEnumOptions.id1.label" },
    { id: 2, label: "constants.criteria.inputOptionsPredefinedEnumOptions.id2.label" },
    { id: 3, label: "constants.criteria.inputOptionsPredefinedEnumOptions.id3.label" },
];
