import { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import Reducer from "./reducer";

const defaultState = {
    nonLoggedIngUser: null,
    loggedInUser: null,
    userGroupList: [],
    organizationCollections: [],
    usersToAdd: [],
    orderSummary: null,

    plugin: {
        isLoading: true,
        isEmbedded: false,
        isDebugMode: false,
        isDisabled: false,
        disableReason: "",
        checkResult: null,
        selectedUserGroup: null,
        hostAppParameters: null,
        licenses: [],
    },
    selectedEditorCheck: null,
};

const Store = ({ children, initialState }) => {
    const [state, dispatch] = useReducer(Reducer, initialState ?? defaultState);
    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

Store.propTypes = {
    children: PropTypes.node.isRequired,
    initialState: PropTypes.object,
};

const Context = createContext(defaultState);

export const useStore = () => {
    return useContext(Context);
};

export default Store;
